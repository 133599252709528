import { FC, ReactNode } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import styles from './style.module.css'
import Menu from '@components/CommandMenu'
import { useHotkeys } from 'react-hotkeys-hook'
import BackToTop from './Top'
import Footer from './Footer'

export const meta = {
  root: 'https://lab.hxrsh.in',
  title: 'Experiments',
  description:
    'A website dedicated to experimenting and documenting magical details in user interfaces.',
  image: 'https://lab.hxrsh.in/banner.png',
  type: 'website',
}

const Wrapper: FC<{
  children: ReactNode
  title?: string
  description?: string
  image?: string
}> = ({ children, title, description, image }) => {
  const router = useRouter()
  useHotkeys('shift+h', () => window.open('/'))

  return (
    <div>
      <Head>
        <title>{title ? title : meta.title}</title>
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/favicons/apple-touch-icon.png'
        />
        <link
          rel='alternate icon'
          type='image/png'
          href='/favicons/favicon.png'
        />
        <meta name='robots' content='follow, index' />
        <meta
          content={description ? description : meta.description}
          name='description'
        />
        <meta property='og:url' content={`${meta.root}${router.asPath}`} />
        <link rel='canonical' href={`${meta.root}${router.asPath}`} />
        <meta property='og:type' content={meta.type} />
        <meta property='og:site_name' content='Harsh Singh' />
        <meta
          property='og:description'
          content={description ? description : meta.description}
        />
        <meta property='og:title' content={title ? title : meta.title} />
        <meta property='og:image' content={image ? image : meta.image} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@harshhhdev' />
        <meta name='theme-color' content='#e5e5e5' />
        <meta name='twitter:title' content={title ? title : meta.title} />
        <meta
          name='twitter:description'
          content={description ? description : meta.description}
        />
        <meta name='twitter:image' content={image ? image : meta.image} />
      </Head>
      <div className={styles.wrapper}>
        <Menu />
        <div className={styles.box}>{children}</div>
        <BackToTop />
        <Footer />
      </div>
    </div>
  )
}

export default Wrapper
