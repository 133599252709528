import {
  Command,
  CommandMenu,
  CommandWrapper,
  useCommands,
  useKmenu,
} from 'kmenu'
import type { FC } from 'react'
import {
  FiArrowRight,
  FiCopy,
  FiDribbble,
  FiFacebook,
  FiGithub,
  FiLinkedin,
  FiShare,
  FiTwitter,
  FiZap,
} from 'react-icons/fi'
import { allPosts } from '@generated/generated'
allPosts.sort((a, b) => (a.published < b.published ? 1 : -1))

const Menu: FC = () => {
  const { setOpen } = useKmenu()

  const main: Command[] = [
    {
      category: 'Navigation',
      commands: [
        {
          text: 'Experiments...',
          icon: <FiZap />,
          perform: () => setOpen(2),
        },
        {
          text: 'Go Home',
          icon: <FiArrowRight />,
          href: '/',
          shortcuts: { keys: ['h'], modifier: 'shift' },
        },
      ],
    },
    {
      category: 'Actions',
      commands: [
        {
          text: 'Share...',
          icon: <FiShare />,
          perform: () => setOpen(3),
        },
        {
          text: 'Copy URL',
          icon: <FiCopy />,
          perform: () => navigator.clipboard.writeText('https://lab.hxrsh.in'),
        },
      ],
    },
    {
      category: 'Socials',
      commands: [
        {
          text: 'Twitter',
          icon: <FiTwitter />,
          href: 'https://twitter.com/harshhhdev',
          newTab: true,
        },
        {
          text: 'GitHub',
          icon: <FiGithub />,
          href: 'https://github.com/harshhhdev',
          newTab: true,
        },
        {
          text: 'Dribbble',
          icon: <FiDribbble />,
          href: 'https://dribbble.com/harshhhdev',
          newTab: true,
        },
      ],
    },
  ]

  const share: Command[] = [
    {
      category: 'Share to...',
      commands: [
        {
          text: 'Twitter',
          icon: <FiTwitter />,
          href: 'https://twitter.com/intent/tweet?text=Check%20out%20these%20cool%20UI%20experiments%20by%20%40harshhhdev%0Ahttps%3A%2F%2Flab.hxrsh.in%2F',
          newTab: true,
        },
        {
          text: 'Facebook',
          icon: <FiFacebook />,
          href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            'https://lab.hxrsh.in'
          )}`,
          newTab: true,
        },
        {
          text: 'Linkedin',
          icon: <FiLinkedin />,
          href: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            'https://lab.hxrsh.in'
          )}`,
          newTab: true,
        },
      ],
    },
  ]

  const experiments: Command[] = [
    {
      category: 'Experiments',
      commands: allPosts.map((post) => {
        return {
          text: post.title,
          icon: <FiZap />,
          href: `/posts/${post.slug}`,
        }
      }),
    },
  ]

  const [mainCommands] = useCommands(main)
  const [experimentsCommands] = useCommands(experiments)
  const [shareCommands] = useCommands(share)

  return (
    <CommandWrapper>
      <CommandMenu commands={mainCommands} index={1} crumbs={['Home']} />
      <CommandMenu
        commands={experimentsCommands}
        index={2}
        crumbs={['Home', 'Search']}
      />
      <CommandMenu
        commands={shareCommands}
        index={3}
        crumbs={['Home', 'Share']}
      />
    </CommandWrapper>
  )
}

export default Menu
